import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../styles';
import { Container } from '@material-ui/core'
import GenericBanner from '../components/GenericBanner'
import Footer from '../components/Footer'

const useStyles = makeStyles(theme => { return {
  ...styles(theme)
}})

function TermsPage() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root}>
        <GenericBanner label="TERMS OF SERVICE" />
        <Container maxWidth="md" className={classes.lightContainer}>

{/* this was created in google docs and then saved as html, then modified for formatting */}
<p className="c6"><span><b><center>TERMS OF SERVICE AGREEMENT</center></b></span></p>
<p className="c2"><span className="c1">This Terms of Service Agreement (&ldquo;Agreement&rdquo;) is an agreement between
        Jellygon, LLC, an Oregon limited liability company (&ldquo;Jellygon&rdquo;, &ldquo;we&rdquo;, or
        &ldquo;our&rdquo;), and you (&ldquo;you&rdquo; or &ldquo;your&rdquo;), individually a &ldquo;Party&rdquo; and
        collectively the &ldquo;Parties&rdquo;.</span></p>
<p className="c2"><span className="c1">This Agreement governs your access to or use of our website, subscriptions, and software
        (&ldquo;Services&rdquo;). By accessing, downloading, or using any part
        of the Services, you agree to be bound by this Agreement. This Agreement affects your legal rights and
        obligations. If you do not agree to be bound by this Agreement, do not access or use the Services.</span></p>
<p className="c2"><span><b>1. </b></span><span><b><u>Terms Applicable to All Services</u></b></span><span
        className="c5 c0">.</span></p>
<p className="c2"><span><b>1.1. Updates to Terms. </b></span><span className="c1">We may update or modify this Agreement at
        any time without notice, although we will make reasonable efforts to inform you of any updates we consider 
        substantial, at our discretion. Your use of the Services following any update or
        modification constitutes your agreement to follow and be bound by the Agreement as updated or modified.</span></p>
<p className="c2"><span><b>1.2. Updates to Services.</b></span><span className="c1">&nbsp;We may give you access to future
        versions of the Services. However, we do not have any obligation to make new versions or content available, or
        to continue to make available for access or download any versions of the Services. We may discontinue work on
        the Services, including the game software, at any time and for any reason.</span></p>
<p className="c2"><span><b>1.3. Restrictions. </b></span><span className="c1">Except as provided in this Agreement, you
        shall not: (i) transfer, give access to, or distribute any part of any Services to any third-party without our
        prior written consent; (ii) attempt to reverse engineer, alter, or modify any part of any Services; or (iii) use
        the Services in any way not expressly permitted in this Agreement.</span></p>
<p className="c2"><span><b>1.4. Ownership and Intellectual Property. </b></span><span className="c1">The Services are
        subject to trademark, service mark, trade dress, copyright, or other intellectual property rights held by
        Jellygon. No right, title, or interest in any of the Services is transferred to you as a result of this
        Agreement. We only provide you with the license to use or access the Services as provided in this
        Agreement.</span></p>
<p className="c2"><span><b>1.5. LIMITED LIABILITY. </b></span><span><b><i>Neither Party will be liable for any
        indirect, special, incidental, or consequential damages, or lost profits that may arise in connection with this
        Agreement. Jellygon&rsquo;s liability to you will not exceed the amount actually paid or payable to Jellygon by
        you under this Agreement.</i></b></span><span className="c1">&nbsp;In the event of breach, your rights are limited to the
        right to seek damages at law, if any, and you have no right to rescind this Agreement or to enjoin
        Jellygon&rsquo;s exploitation of the Services.</span></p>
<p className="c2"><span><b>1.6. EXCLUSION OF IMPLIED AND OTHER WARRANTIES.</b></span><span><b><i>&nbsp;The
        Services are provided &ldquo;AS IS&rdquo;. Jellygon makes no warranties, express or implied, and disclaims all
        implied warranties, including any warranty of merchantability or warranty of fitness for a particular
        purpose.</i></b></span></p>
<p className="c2"><span><b>1.7. Indemnification. </b></span><span className="c1">You shall indemnify Jellygon and its
        owners, agents, and representatives (each an &ldquo;Indemnified Party&rdquo;) against any claims, actions,
        damages, liabilities, losses, or costs, including attorneys&rsquo; fees and costs incurred in the settlement or
        avoidance of any such claim, incurred by or asserted against any Indemnified Party resulting from or arising in
        connection with: (i) your access to or use of the Services; or (ii) any breach or alleged breach by you of any
        of your representations, warranties, covenants, or obligations under this Agreement.</span></p>
<p className="c2"><span><b>1.8. Transferability. </b></span><span className="c1">You shall not transfer or assign this
        Agreement or any right or obligation of this Agreement, by operation of law or otherwise, without the prior
        written consent of Jellygon.</span></p>
<p className="c2"><span><b>1.9. No Agency. </b></span><span className="c1">Nothing in this Agreement will be deemed to
        create any association, partnership, employment, joint venture, or agency relationship between the Parties. The
        Parties will not have the right or authority to create any obligations of any kind, make any representation or
        warranty on behalf of the other Party, or to bind the other Party in any respect, unless approved in writing by
        both Parties.</span></p>
<p className="c2"><span><b>1.10. Termination. </b></span><span className="c1">You may terminate this Agreement at any time
        by deactivating your accounts and discontinuing your use of the Services. We may suspend or terminate your
        accounts or this Agreement, or cease providing you with all or part of the Services, at any time and will make
        reasonable efforts to notify you of such action.</span></p>
<p className="c2"><span><b>1.11. General. </b></span><span className="c1">This Agreement constitutes the entire agreement
        between the Parties and supersedes all prior understanding, whether written or oral. Except as provided in this
        Agreement, no term or condition of this Agreement may be amended or deemed waived, except by a writing signed by
        the Parties that refers to this Agreement. If any term or part of a term of this Agreement is invalid, illegal,
        or unenforceable, the rest of the Agreement will remain in effect. This Agreement will be governed by and
        construed in accordance with the laws of the state of Oregon, without regard to conflict of laws principles. Any
        claim arising out of this Agreement, including tort claims, must be resolved in Multnomah County, Oregon.</span>
</p>
<p className="c2"><span><b>2. </b></span><span><b><u>Site and Subscription Terms</u></b></span><span
        className="c5 c0">.</span></p>
<p className="c2"><span><b>2.1. Your Account. </b></span><span className="c1">You must create an account with Jellygon to
        use the Services. You agree to provide and maintain true, accurate, current, and complete information about you.
        You are responsible for maintaining the confidentiality of your password and account, and are solely responsible
        for any activities that occur under your password or account. You agree to immediately notify us of any
        unauthorized use of your password or account or any other breach of security.</span></p>
<p className="c2"><span><b>2.2. Subscription; Payment. </b></span><span className="c1">We require a subscription payment to
        access the entire Services, including Jellygon&rsquo;s game software. We use these payments to generally help
        fund development of Jellygon&rsquo;s game software. Subscription payments are nonrefundable and will
        automatically renew until you cancel your subscription, which you may do at any time through your account
        preferences with our payment processor. You must set up a subscription payment if you wish to access the entire
        Services. If you do not pay for a subscription and only create an account, or cancel your subscription payment,
        only some of the Services will be available to you.</span></p>
<p className="c2"><span><b>2.3. Third-Party Processor.</b></span><span className="c1">&nbsp;We do not process payments for
        the Services. Payments are processed by the third-party service Stripe provided by Stripe, Inc.
        (&ldquo;Stripe&rdquo;). Stripe&rsquo;s terms of service, privacy policy, or other terms govern your relationship
        with them and their use of your personal information. You can find such terms on Stripe&rsquo;s website:
        https://stripe.com/. You agree that we are not liable for any loss or claim that you may have against Stripe,
        and release us from any claims or liability related to your agreement with Stripe.</span></p>
<p className="c2"><span><b>2.4. Google Analytics. </b></span><span className="c1">We use Google Analytics on our website to
        better understand who is visiting our site. Google collects information such as the web address of the page that
        you are visiting and your IP address, and may also set cookies on your browser or read cookies that are already
        there. Any information collected by just visiting our website is collected by Google. You can find out more,
        including how Google uses your information and how you can control the information sent to Google, by visiting
        Google&rsquo;s Partners page at https://policies.google.com/technologies/partner-sites.</span></p>
<p className="c2"><span><b>2.5. Cookies.</b></span><span className="c1">&nbsp;We use cookies and related technologies such
        as browser storage in order to provide the Services; for example, to store your website preferences or recognize
        you without requiring your password. &nbsp;In addition, third-parties we rely on such as Google Analytics or
        Stripe may use cookies and related technologies as part of their services.</span></p>
<p className="c2"><span><b>2.6. Mailing List. </b></span><span className="c1">You may opt in to our mailing list. If you opt
        in, you consent to our processing and storage of your information for email marketing purposes. &nbsp;Your email
        address and other relevant information may be used with or stored on third-party mailing services such as
        MailChimp for the purposes of helping us send email and manage unsubscribe lists.</span></p>
<p className="c2"><span><b>3. </b></span><span><b><u>Game Software Terms</u></b></span><span className="c5 c0">.</span></p>
<p className="c2"><span><b>3.1. Game Software.</b></span><span className="c1">&nbsp;As part of the paid Services, we grant
        you a license to access, download, and use our game software. The game software may include additional terms or
        conditions that you are required to accept before downloading or installing, and those terms or conditions are
        incorporated into this Agreement. We reserve the right, in our sole discretion and without prior notice or
        liability to you, to decline to license the software to any party and to modify or stop providing the game
        software or any part of the game software to you or to users generally. The game software is provided to you for
        entertainment purposes only.</span></p>
<p className="c2"><span><b>3.2. License. </b></span><span className="c1">The Services are licensed to you for use by a
        single user. You may store the software on any of your computers, but the software cannot be shared with others.
        Upon payment, we grant you a personal, worldwide, royalty free, nonassignable, nonsublicensable, revocable, and
        nonexclusive license to access, download, and use the game software (&ldquo;License&rdquo;), provided that you:
        (i) shall not transfer, give access to, copy, or distribute any part of the game software without our prior
        written consent; (ii) shall not attempt to reverse engineer, alter, disrupt, or modify any part of the game
        software; and (iii) comply with this Agreement. We retain all rights in our software, and you acknowledge and
        agree that you have no rights in the software outside of this License.</span></p>
<p className="c2"><span><b>3.3. Streaming. </b></span><span className="c1">We grant you a worldwide, royalty free,
        nonassignable, nonsublicensable, revocable, and nonexclusive license to stream or otherwise upload and display
        your gameplay of the game software to video platforms such as Twitch or YouTube. You acknowledge and agree that
        you are solely responsible for such use and any related consequences, including third-party claims against
        Jellygon resulting from or arising in connection with your use, and represent and warrant that your use will not
        infringe any intellectual property right or other right of any third-party.</span></p>
<p className="c2"><span><b>3.4. Gameplay Analytics; User Data. </b></span><span className="c1">We may collect or authorize
        third-parties to collect and share with Jellygon analytics about your gameplay. We may also store the data,
        information, or actions you put into our game software&rsquo;s online components, such as what you say or build
        in the game, and these actions may be visible to other users or difficult to remove.</span></p>
<p className="c2"><span><b>3.5. Interaction with Users. </b></span><span className="c1">The Services may support interaction
        with other users. If so, users will be able to see your activity on the Services, communicate with you within
        the Services, or view your profile, which may include your name and other profile information. You are fully
        responsible for your interactions with other users and the results of those interactions.</span></p>
<p className="c2"><span><b>4. </b></span><span><b><u>Privacy Terms</u></b></span><span className="c5 c0">.</span></p>
<p className="c2"><span><b>4.1. Storage. </b></span><span className="c1">We store the information we collect through the
        Services on our systems or licensed systems, such as Amazon Web Services, and use it for the purposes described
        in this Agreement.</span></p>
<p className="c2"><span><b>4.2. Analytics and Third-Parties. </b></span><span className="c1">We may use a variety of
        third-party services, including analytics tools, to help provide the Services or to measure or improve the use
        of the Services. These third-party service providers may use tracking technologies to collect information sent
        by your device, and may share such information with Jellygon.</span></p>
<p className="c2"><span><b>4.3. Withdrawing Consent. </b></span><span className="c1">You may withdraw your consent to our
        processing of your personal information and your use of the Services. You can do this at any time by emailing
        Jellygon to request that your personal information be deleted. If you withdraw your consent to the use or
        sharing of your personal information, you may not have access to any of the Services, and we might not be able
        to provide you any of the Services. Some of your information (such as in-game interactions or data stored with 
        third-parties) may not be possible to delete.  Please note that we may continue to process your information after you have
        withdrawn consent and requested that we delete your personal information if we have a legal basis to do so. For
        example, we may retain certain information if we need to do so to comply with an independent legal obligation,
        or if it is necessary to do so to pursue our legitimate interest in keeping the Services safe and secure.  Also, 
        we may maintain information about sales transactions in order to complete transactions and 
        for record keeping purposes.</span></p>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default TermsPage